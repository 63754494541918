import React, { useEffect, useState } from 'react';

const Cookie = () => {
    const [cookieOk, setCookieOk] = useState<boolean>(true);

    const checkCookie = () => {
        const nyCookie = localStorage.getItem("ny_cookie");
        if (!nyCookie) {
            setCookieOk(false);
        }
    };

    useEffect(() => {
        checkCookie();
    }, []);

    return (
        <>
            {!cookieOk && (
                <div className='cookie-container'>
                    <div className='cookie'>
                        <p>
                            Nous utilisons des cookies pour améliorer votre expérience. En continuant à visiter ce site, vous acceptez notre utilisation des cookies et vous acceptez également <a href='/condition'>nos conditions d'utilisation</a>.
                        </p>
                        <div className='action'>
                            <button className='ok' onClick={() => { 
                                setCookieOk(true); 
                                localStorage.setItem("ny_cookie", "accepted"); 
                            }}>J'accepte</button>
                            <button className='no' onClick={() => { 
                                window.location.href = 'https://www.google.com'; 
                            }}>Je refuse</button>
                        </div>
                    </div>
                </div>
            )}
            {cookieOk && <div></div>}
        </>
    );
};

export default Cookie;
