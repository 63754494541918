import React, { useEffect, useState } from "react";
import { propsType } from "../Navigation";
import '../css/Notification.css'
import { getAllNotif, getFileById, updateNotif } from "../services/exchange";
import { Notification, User } from "../models/global";
import NotificationBox from "../components/NotificationBox";
import { IoClose } from "react-icons/io5";
import Cookie from "../components/Cookie";
import { Helmet } from "react-helmet";

const Notifications = (props: propsType)=>{
    const user: User = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : {}
    const [notifs, setNotifs] = useState<Notification[]>([])
    const [selectedNotif, setSelectedNotif] = useState<Notification>({})
    const [showModal, setShowModal] = useState<boolean>(false)
    const [preuveURI, setPreuveURI] = useState<string>('')
    const init = () =>{
        if(user.id) getAllNotif(user.id).then(_list=>{
            props.setIsLoading(false);
            if(_list){
                setNotifs(_list);
            }
        })
        else{
            props.setIsLoading(false);
        }
    }

    const selectNotif = async (notif: Notification) => {
        setSelectedNotif(notif);
        if(!notif.is_seen){
            props.setIsLoading(true);
            await updateNotif(notif.id!, {...notif, is_seen: true});
            init();
        }
        if(notif.file_id){
            getFileById(notif.file_id).then(uri=>{
                if(uri){
                    setPreuveURI(uri);
                    setShowModal(true);
                }
            })
        }else{
            setShowModal(true);
        }
    }

    useEffect(()=>{
        init();
    }, [])
    return(
        <>
        <div className="notif-container">
            <Helmet>
                <meta charSet='utf-8' name='description' content="Restez à jour avec les notifications sur NY EXCHANGE MADA. Recevez des alertes en temps réel sur vos transactions, les tendances du marché et les actualités importantes. Ne manquez aucune opportunité d'échange et optimisez vos investissements en cryptomonnaies avec nos notifications personnalisées."/>
                <title>Notifications</title>
                <link rel="canonical" href="https://nyexchangemada.com/notifications"/>                                                                                               
            </Helmet>
            <h2>Notifications</h2>
            {notifs.length === 0 && (<div className="no-notif">AUCUNE NOTIFICATION POUR LE MOMENT</div>)}
            {notifs.length > 0 && notifs.map((notif: Notification, key: number)=><NotificationBox selectNotif={selectNotif} key={key} notif={notif}  />)}
            {showModal && (<div className="notif-modal">
                <div className='n-m-btn-container'>
                    <button onClick={()=>setShowModal(false)}>
                        <IoClose size={25} />
                    </button>
                </div>
                <div className='n-modal-content'>
                    <p>{selectedNotif.content}</p>
                    {selectedNotif.file_id && (<div>
                        <h2>CAPTURE D'ECRAN DU TRANSACTION</h2>
                        <img src={`https://nyexchangemada.com/api/${preuveURI}`} alt="preuve-uri"/>
                    </div>
                    )}
                </div>
            </div>)}
        </div>
        <Cookie/>
        </>
    )
}

export default Notifications