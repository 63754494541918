import React, { useEffect } from "react";
import { propsType } from "../Navigation";
import "../css/About.css";
import Cookie from "../components/Cookie";
import { Helmet } from "react-helmet";

const About = (props: propsType)=>{
    useEffect(()=>{
        props.setIsLoading(false);
    }, [])
    return(
        <>
        <div className="about-container">
        <Helmet>
            <meta charSet='utf-8' name='description' content="Découvrez l'histoire de NY EXCHANGE MADA, votre plateforme de référence pour l'échange de cryptomonnaies en Ariary. Apprenez-en plus sur notre mission, notre vision et notre équipe dédiée à vous offrir une expérience d'échange sécurisée et efficace. Rejoignez-nous dans notre aventure et participez à la révolution des cryptomonnaies !"/>
            <title>A propos</title>
            <link rel="canonical" href="https://nyexchangemada.com/about"/>                                                                                               
        </Helmet>


            <span>Bievenue ! chez,</span>
            <div className="about-title">NY - EXCHANGE MADA</div>
            <p>NY - Exchange Mada se distingue par son engagement envers la sécurité et la transparence dans chaque transaction. Notre plateforme offre une interface conviviale et intuitive, facilitant ainsi l'accès aux marchés de cryptomonnaie pour les utilisateurs malgaches.
<br></br>
<br></br>
En tant que leader du marché dans le domaine des échanges de cryptomonnaie en ariary à Madagascar, nous nous efforçons continuellement d'optimiser notre plateforme pour répondre aux besoins évolutifs de nos clients. Notre équipe dévouée travaille sans relâche pour garantir une expérience utilisateur exceptionnelle, mettant l'accent sur la rapidité des transactions et la fiabilité du service.
<br></br>
<br></br>
Chez NY - Exchange Mada, nous croyons en la création d'un écosystème financier numérique robuste et accessible à tous. Que vous soyez un investisseur chevronné ou que vous découvriez le monde passionnant des cryptomonnaies, notre plateforme vous offre les outils nécessaires pour maximiser vos opportunités et atteindre vos objectifs financiers.
<br></br>
<br></br>
Rejoignez-nous dès aujourd'hui et faites partie de la révolution financière numérique à Madagascar avec NY - Exchange Mada, votre partenaire de confiance pour les échanges de cryptomonnaie en ariary.
            </p>
        </div>
        <Cookie/>
        </>)
}

export default About;