import React, { useEffect, useState } from 'react';
import "../css/Home.css";
import Balance from '../components/Balance';
import Cours from '../components/Cours';
import Money from '../components/Money';
import Unavailable from '../components/Unavailable';
import { createOrder, getAllBalance, getAllCours, getAllMoney, getMgaMoney, getSystemState, getUsdBalance, updateMgaMoney, updateOrder, updateUsdBalance } from '../services/exchange';
import { MESSAGE_TYPE, MgaMoney, Order, UsdBalance, UsdMoney, User, balance, cours } from '../models/global';
import { MdOutlineCurrencyExchange } from "react-icons/md";
import { MdOutlineShoppingBag } from "react-icons/md";
import ExchangeModal from '../components/ExchangeModal';
import { propsType } from '../Navigation';
import { useNavigate } from 'react-router-dom';
import Cookie from '../components/Cookie';
import { Helmet } from "react-helmet";



const Home = (props: propsType)=>{
    const [systemState, setSystemState] = useState<boolean>(false);
    const [balances, setBalances] = useState<balance>({airtel_money: 0, crypto: 0, m_vola: 0, orange_money: 0, payeer: 0, pm: 0})
    const [cours, setCours] = useState<cours>({depot_crypto: 0, depot_fiat: 0, retrait_crypto_inf_1000: 0, retrait_crypto_sup_1000: 0, retrait_fiat_inf_500: 0, retrait_fiat_sup_500: 0});
    const [moneys, setMoneys] = useState<any>([]);
    const [newOrder, setNewOrder] = useState<Order>({wallet: 'BINANCE'});
    const [step, setStep] = useState<number>(0);
    const [choisedMoney, setChoisedMoney] = useState<any>({});
    const [mga_account, setMgaAccount] = useState<MgaMoney>({})
    const [usdBalance, setUsdBalance] = useState<UsdBalance>({})


    const navigate = useNavigate();
    const navigateTo = (link: string)=>{
        navigate(link);
    }
    const init = async()=>{
        await getSystemState().then(state=>{
            if(state)setSystemState(state);
        });
        await getAllCours().then(allCours=>{
            if(allCours) setCours(allCours);
        })
        await getAllBalance().then(allBalance=>{
            if(allBalance) setBalances(allBalance);
            props.setIsLoading(false);
        })
        await getAllMoney().then(moneyList=>{
            if(moneyList)setMoneys(moneyList);
        })
    }

    const startExchange = (money: UsdMoney) => {
        if(!localStorage.getItem('user')) props.parentVoid!();
        else{
            setNewOrder({wallet: 'BINANCE', money_usd: money.name});
            setStep(1);
            setChoisedMoney(money);
        }
    }

    const submitExchange = (e: any) =>{
        e.preventDefault();
        let user: User = JSON.parse(localStorage.getItem('user')!);
        setNewOrder({...newOrder, user_id: user.id, is_finish: true});
        props.setIsLoading(true);
        createOrder({...newOrder, user_id: user.id, is_finish: true}).then(result=>{
            if(result){
                props.pushMsg!('Echange envoyé', MESSAGE_TYPE.SUCCESS);
                getMgaMoney(newOrder.money_mga!).then(account=>{
                    if(account){
                        setMgaAccount(account);
                        getUsdBalance(choisedMoney.type === 'CRYPTO' ? 'CRYPTO M' : newOrder.money_usd === 'USD PAYEER' ? 'USD PAYEER' : 'USD PM').then(balance=>{
                            if(balance){
                                props.setIsLoading(false);
                                setStep(step + 1);
                                setUsdBalance(balance);
                            }
                        })
                    }
                })
                setNewOrder(result);
            }
        })
    }

    const doneExchange = async()=>{
        props.setIsLoading(true);
        if(newOrder.type === 'Dépot'){
            await updateMgaMoney(mga_account.id!, {...mga_account, solde: mga_account.solde! + newOrder.amount_mga!});
            await updateUsdBalance(usdBalance.id!, {...usdBalance, balance: usdBalance.balance! - newOrder.amount!});
        }else{
            await updateMgaMoney(mga_account.id!, {...mga_account, solde: mga_account.solde! - newOrder.amount_mga!});
            await updateUsdBalance(usdBalance.id!, {...usdBalance, balance: usdBalance.balance! + newOrder.amount!});
        }
        await updateOrder(newOrder.id!, {...newOrder, is_payed: true});
        props.pushMsg!('Votre échange est en cours. Nous vous enverrons une notification une fois celui-ci terminé. NY - EXCHANGE MADA vous remercie.', MESSAGE_TYPE.INFORMATION);
        props.setIsLoading(false);
        setStep(0);
        setNewOrder({wallet: 'BINANCE'})
    }

    useEffect(()=>{
        init();
        const intervalId = setInterval(() => {
        init();
        }, 60000);
        return () => clearInterval(intervalId);
    }, [])

    return (
        <>
            <div className='home'>
                <Helmet>
                    <meta charSet='utf-8' name='description' content="Découvrez NY EXCHANGE MADA, votre plateforme de confiance pour échanger des cryptomonnaies en Ariary. Profitez d'une interface conviviale, d'un service rapide et sécurisé, et de taux compétitifs pour vos transactions. Rejoignez-nous dès aujourd'hui et transformez votre expérience d'échange de cryptomonnaies !"/>
                    <title>NY EXCHANGE MADA</title>
                    <link rel="canonical" href="https://nyexchangemada.com"/>                                                                                               
                </Helmet>
                <Balance BalanceData={balances}/>
                <Cours CoursData={cours}/>
                <div className='title'><MdOutlineCurrencyExchange size={20}/><span>ECHANGES</span></div>
                <div className={`money-list-container${systemState ? '' : '-unavailable'}`}>
                    {moneys.map((money: any, key: number) => <Money key={key} Data={money} toStart={startExchange}/>)}
                {!systemState && <Unavailable />}
                </div>
                <div className='title'>
                    <MdOutlineShoppingBag size={20}/>
                    <span>SHOPS</span>
                </div>
                <div className='product-container'>
                    EN DEVELOPPEMENT
                </div>
            </div>
            {step > 0 && (<ExchangeModal pusMsg={props.pushMsg!} doneExchange={doneExchange} mga_account={mga_account} coursData={cours} balanceData={balances} onSubmit={submitExchange} choisedMoney={choisedMoney} step={step} setStep={setStep} order={newOrder} setOrder={setNewOrder}/>)}
            <Cookie/>
        </>
    )
}

export default Home;